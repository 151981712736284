<template>
  <main>
    <h1>Success foreign payment</h1>
    <div>{{ message }}</div>
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
  </main>
</template>

<script>
import errorModule from '@/util/error.handle'

export default {
  name: 'SuccessForeignPayment',
  mounted() {
    this.verifyTransaction()
  },
  data() {
    return {
      message: null,
      notification: null,
    }
  },
  methods: {
    async verifyTransaction() {
      try {
        this.message = localStorage.getItem('trn_trn-qdr')

        const { status } = await this.axios.post(
          '/account/wallet/quadropay/success',
          {
            reference_id: this.message,
          }
        )

        if (status === 200) {
          this.notification = {
            type: 'success',
            message: 'Transaction successful',
          }
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>
